import { render, staticRenderFns } from "./company-preview.vue?vue&type=template&id=2bbfff26&scoped=true&"
import script from "./company-preview.vue?vue&type=script&lang=js&"
export * from "./company-preview.vue?vue&type=script&lang=js&"
import style0 from "./company-preview.vue?vue&type=style&index=0&id=2bbfff26&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bbfff26",
  null
  
)

export default component.exports