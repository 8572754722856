<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.aboutUs {
  width: 100%;
  background: #070911;
  font-family: "PingFangSC-Regular", "PingFang SC";
}
.aboutUs-details {
  width: 76.7%;
  height: 100%;
  max-width: 1104px;
  margin: 0 auto;
  padding: 196px 0;
  color: rgba(222, 230, 255, 1);
  &-first {
    width: 100%;
    height: 400px;
    background: rgba(15, 17, 24, 1);
    border-radius: 10px;
    padding: 20px 23px;
    margin-bottom: 80px;
    &-container {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 2px solid rgba(222, 230, 255, 0.29);
      text-align: center;
      &-top {
        width: 100%;
        height: 220px;
        margin-top: 50px;
        display: flex;
        &-left {
          width: 50%;
          line-height: 220px;
          border-right: 1px solid #dee6ff;
          img {
            width: 260px;
            vertical-align: middle;
          }
        }
        &-right {
          width: 50%;
          padding: 0 6%;
          img {
            width: 86px;
          }
        }
      }
      &-main {
        width: 100%;
        height: 220px;
        margin-top: 50px;
        img {
          width: 86px;
        }
        p {
          width: 70%;
          margin-left: 15%;
          margin: 0 auto;
          &:nth-child(2) {
            font-size: 30px;
            font-weight: 600;
            color: rgba(222, 230, 255, 1);
            line-height: 20px;
            letter-spacing: 2px;
            margin: 12px auto;
          }
          &:nth-child(3) {
            font-size: 14px;
            font-weight: 400;
            color: rgba(222, 230, 255, 1);
            line-height: 22px;
            margin-top: 16px;
          }
        }
        .sure-button {
          width: 140px;
          height: 40px;
          margin: 0 auto;
          margin-top: 51px;
          text-align: center;
          line-height: 40px;
          letter-spacing: 0;
          cursor: pointer;
          background: linear-gradient(
            211deg,
            rgba(248, 40, 108, 1) 0%,
            rgba(249, 98, 47, 1) 100%
          );
          border-radius: 29px;
          font-weight: 600;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  .person-center-wrapper-selfInfo-title {
    width: 100%;
    padding-top: 28px;
    display: flex;
    justify-content: flex-start;
    height: 40px;
    font-size: 20px;
    // line-height: 40px;
    &-left {
      width: 4px;
      height: 20px;
      background: rgba(85, 119, 224, 1);
      border-radius: 5px;
    }
    &-right {
      font-size: 18px;
      font-weight: 400;
      color: rgba(85, 119, 224, 1);
      margin-left: 10px;
    }
  }
  .normal {
    font-size: 16px;
    font-family: "PingFangSC-Regular", "PingFang SC";
    font-weight: 400;
    color: rgba(222, 230, 255, 1);
    line-height: 40px;
    letter-spacing: 2px;
  }
  &-third {
    margin-top: 101px;
    width: 100%;
    height: 618px;
    background: #0f1118;
    position: relative;
    .idc-fifth-container {
      width: 84%;
      margin-left: 8%;
      padding-top: 5%;
      overflow: hidden;
      text-align: center;
      img {
        width: 870px;
        height: 500px;
      }
    }
  }
  /deep/
    .idc-fifth-container.swiper-container-initialized.swiper-container-horizontal
    > .swiper-button-next,
  .swiper-button-prev {
    width: 51px;
    height: 51px;
    top: 50%;
    background: rgba(222, 230, 255, 1);
    border-radius: 50%;
    color: #0f1118;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    // margin-left: 50px;
    // margin-right: 50px;
    &:after {
      font-size: 14px;
      font-weight: bolder;
    }
  }
}
</style>

<template>
  <div class="aboutUs">
    <div class="aboutUs-details">
      <div class="aboutUs-details-first">
        <div class="aboutUs-details-first-container">
          <template v-if="previewType">
            <div class="aboutUs-details-first-container-top">
              <div class="aboutUs-details-first-container-top-left">
                <img :src='awardImg'
                     alt="">
              </div>
              <div class="aboutUs-details-first-container-top-right">
                <img :src="'/api/static/'+company.logoImg"
                     alt="">
                <p>
                  {{ company.brandName }}
                </p>
                <p>
                  {{ company.introduce }}
                </p>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="aboutUs-details-first-container-main">
              <img :src="'/api/static/'+company.logoImg"
                   alt="">
              <p>
                {{ company.brandName }}
              </p>
              <p>
                {{ company.introduce }}
              </p>
              <div class="sure-button"
                   v-if="recommend"
                   @click="recommendT">
                {{ company.checked ? '取消推荐' : '我要推荐' }}
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="aboutUs-details-second">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            名品信息
          </div>
        </div>
        <p class="normal">
          {{ company.explain }}
        </p>
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            名品优势
          </div>
        </div>
        <p class="normal">
          {{ company.adv }}
        </p>
      </div>
      <div class="aboutUs-details-third">
        <div class="idc-fifth-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide"
                 v-if="$route.params.brandObj.videoUrl">
              <img src="../assets/Buttons_Play.png"
                   alt=""
                   :style="{cursor: 'pointer'}"
                   @click="linkTo($route.params.brandObj.videoUrl, '_blank')">
            </div>
            <div class="swiper-slide"
                 v-for="(item, index) in imgArray"
                 :key="index">
              <img :src="'/api/static/'+item"
                   alt="">
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
      <Common-footer></Common-footer>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import CommonFooter from '../components/common-footer';
export default {
  name: 'aboutUs-details',
  data () {
    return {
      recommend: false,
      company: {
        brandName: '',
        introduce: '',
        explain: '',
        adv: '',
        logoImg: ''
      },
      previewType: null,
      AWARDSPrideList: [
        {
          img: require('../assets/price-1.png'),
          type: 'city_impression'
        },
        {
          img: require('../assets/price-2.png'),
          type: 'best_online'
        },
        {
          img: require('../assets/price-3.png'),
          type: 'annual_city'
        },
        {
          img: require('../assets/price-4.png'),
          type: 'recommendation'
        },
        {
          img: require('../assets/price-5.png'),
          type: 'original'
        },
        {
          img: require('../assets/price-6.png'),
          type: 'hot_comment'
        }
      ]
    }
  },
  created () {
    if (this.$route.params.from === 'jiangnan') {
      this.company = this.$route.params.brandObj;
      this.previewType = this.$route.params.price;
    } else {
      this.recommend = true;
      this.company = this.$route.params.brandObj;
    }
  },
  mounted () {
    new Swiper('.idc-fifth-container', {
      autoplay: true,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  },
  computed: {
    imgArray () {
      return [this.company.previewUrl, this.company.previewUrl1, this.company.previewUrl2].filter(item => item !== '');
    },
    awardImg () {
      let tempImg = null;
      let findIndex = this.AWARDSPrideList.findIndex(item => item.type === this.previewType);
      if (findIndex > -1) {
        tempImg = this.AWARDSPrideList[findIndex].img;
      }
      return tempImg;
    }
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    },
    recommendT () {
      let user = JSON.parse(this.$Cookies.get('cecc-user'));
      if (this.company.checked) {
        this.$api.deleteAdmin_recommend({
          city: user.city,
          product_id: this.company.id
        }).then(() => {
          this.company.checked = false;
          this.$message.success('取消推荐成功');
        })
      } else {
        this.$api.postAdmin_recommend({
          city: user.city,
          product_id: this.company.id
        }).then(() => {
          this.company.checked = true;
        }).catch(() => {
          this.$message.error('服务器出错了请稍后再试');
        })
      }
    }
  },
  components: {
    CommonFooter
  },
}
</script>
